import React from "react";
import Scene from "./Scene";
import Navbar from "./Navbar";
import Loader from "./Loader";
import Overlay from "./layout/Overlay";
import { ReactLenis } from "@studio-freight/react-lenis";

function App() {
  return (
    <>
      <Scene />
      {/* <Loader /> */}
      <ReactLenis root>
        <Overlay />
      </ReactLenis>
      <Navbar />
    </>
  );
}
export default App;
