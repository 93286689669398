function Contact() {
  return (
    <section className="contact_container">
      <h2 id="contact"> Feel free to reach out! </h2>
      <p>
        {" "}
        <a href="mailto:m.mark.frazier@gmail.com" className="contact_link">
          m.mark.frazier@gmail.com
        </a>{" "}
      </p>
      <div className="designed_by">Designed & Built by Mark Frazier</div>
    </section>
  );
}

export default Contact;