import React, { useEffect, useRef } from "react";
import gsap from "gsap";

export default function Skills() {
  const skills = [
    "Python",
    "React",
    "Flask",
    "Express",
    "JavaScript",
    "HTML/CSS",
    "NodeJS",
    "PostgresSQL",
    "MongoDB",
    "Git",
    "Postman",
    "Cinema 4D",
    "Adobe AE",
    "ThreeJS",
    "React-Three-Fiber",
  ];

  const primaryRefs = useRef([]);
  const secondaryRefs = useRef([]);
  const timelines = useRef([]);

  useEffect(() => {
    primaryRefs.current = primaryRefs.current.slice(0, skills.length);
    secondaryRefs.current = secondaryRefs.current.slice(0, skills.length);

    for (let i = 0; i < skills.length; i++) {
      let tl = gsap.timeline({
        defaults: { ease: "power4.inOut", duration: 0.4 },
      });

      tl.to(primaryRefs.current[i], {
        yPercent: -100,
      })
        .to(
          secondaryRefs.current[i],
          {
            yPercent: -100,
          },
          "<"
        );

      tl.pause();

      timelines.current.push(tl);
    }
  }, [skills]);

  const mouseHover = (index) => {
    if (timelines.current[index]) {
      timelines.current[index].play();
    }
  };

  const mouseLeave = (index) => {
    if (timelines.current[index]) {
      timelines.current[index].reverse();
    }
  };

  return (
    <section className="skills_container">
      
        <h1 id="skills">
          <span className="expertise"> Skills & Tools </span>
        </h1>
        <ul className="skills-list">
          {skills.map((skill, i) => (
            <li key={i}>
              <div
                ref={(el) => (primaryRefs.current[i] = el)}
                onMouseEnter={() => mouseHover(i)}
                onMouseLeave={() => mouseLeave(i)}
                className="primary"
              >
                {skill}
              </div>
              <div
                ref={(el) => (secondaryRefs.current[i] = el)}
                onMouseEnter={() => mouseHover(i)}
                onMouseLeave={() => mouseLeave(i)}
                className="secondary"
              >
                {skill}
              </div>
            </li>
          ))}
        </ul>
      
    </section>
  );
}
