import ReactDOM from 'react-dom/client'
import './styles.css';
import App from './App'

const root = ReactDOM.createRoot(document.querySelector('#root'))

root.render(
        <App />
)


