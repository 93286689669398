
import Contact from "../Contact";
import Portfolio from "../Portfolio";
import Skills from "../Skills";
import Intro from "../Intro";

export default function Overlay() {

  return (
    <>
      <Intro />
      <Skills /> 
      <Portfolio />
      <Contact /> 
    </>

  )
}
