import * as React from 'react'
import { useProgress, Html } from '@react-three/drei'

export default function SuspenseLoader() {
  const { progress,} = useProgress()
  return (
    <Html center >
      <div className='suspense-loader'> {progress.toFixed(1)} % loaded </div>
      <img className="loader" src="loader.svg" alt="Loading animation" />
    </Html>
  )
}
