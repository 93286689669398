import { useTexture } from "@react-three/drei";

const Planet = ({ size, position, matCapTexture }) => {
    const texture = useTexture(matCapTexture)

    return (
        <mesh position={position}>
            <sphereGeometry args={size} />
            <meshMatcapMaterial matcap={texture} />
        </mesh>
    )
}

export default Planet;

