import * as THREE from "three";
import { useRef, useState, useEffect } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { useGLTF } from "@react-three/drei";

export default function Rocket({ index, z, speed }) {
  const [scrollPosition, setScrollPosition] = useState(0);
  const rocketRef = useRef();
  const { nodes, materials } = useGLTF("/rocket-transformed.glb");
  const { viewport, camera } = useThree(); //Used to extract info for calculations
  const { height } = viewport.getCurrentViewport(camera, [0, 0, z]); // get viewport data from camera's perspective

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  const [data] = useState({
    // Randomly distributing the objects along the vertical
    x: THREE.MathUtils.randFloatSpread(2.5), // if 6 passed in, returns value from -3 to 3
    y: THREE.MathUtils.randFloatSpread(height + 30),
    rX: Math.random() * Math.PI,
    rY: Math.random() * Math.PI,
    rZ: Math.random() * Math.PI,
  });

  // Get scroll position
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]);

  useFrame((state) => {
    rocketRef.current.rotation.set(0, (data.rY += 0.004), 0);
    rocketRef.current.position.set(data.x * 50, (data.y += 0.05 * speed), z);

    if (data.y > height + 8) {
      data.y = -height - 25;
    }

    state.camera.position.set(0, scrollPosition / -250, 0);
  });

  /*
Mesh -->  Auto-generated by: https://github.com/pmndrs/gltfjsx
3D model source: https://www.thepixellab.net/freebies
title: Cartoon Style Rocket Ship
*/

  return (
    <mesh ref={rocketRef} scale={0.002}>
      <group position={[-1458.3, -1204.52, -1417.89]}>
        <mesh
          geometry={nodes["Cartoon-Rocket1-Mat2"].geometry}
          material={materials["Mat.2"]}
          transparent="true"
          opacity={0.5}
          material-emissive="grey"
        />
        <mesh
          geometry={nodes["Cartoon-Rocket1-Plastic"].geometry}
          material={materials.Plastic}
          transparent="true"
          opacity={0.5}
          material-emissive="white"
        />
        <mesh
          geometry={nodes["Cartoon-Rocket1-Nuovo1"].geometry}
          material={nodes["Cartoon-Rocket1-Nuovo1"].material}
          transparent="true"
          opacity={0.9}
          material-emissive="blue"
        />
        <mesh
          geometry={nodes["Cartoon-Rocket1-Nuovo2"].geometry}
          material={materials["Nuovo.2"]}
          transparent="true"
          opacity={0.9}
          material-emissive="orange"
        />
        <mesh
          geometry={nodes["Cartoon-Rocket1-Nuovo1_1"].geometry}
          material={nodes["Cartoon-Rocket1-Nuovo1_1"].material}
        />
        <mesh
          geometry={nodes["Cartoon-Rocket1-Nuovo1_2"].geometry}
          material={nodes["Cartoon-Rocket1-Nuovo1_2"].material}
        />
      </group>
    </mesh>
  );
}
