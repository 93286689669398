const projects = [
	{
		num: 1,
		id: 'project1',
		title: 'Moto Configurator',
		imageSrc: 'https://imgur.com/uxfXod8.png',
		description:
			'This application allows users to select and style/color different parts of a 3D motorcycle. User can highlight specific parts of the motorcyle, make changes, and download a PDF of the customized model. This app utilizes React, ThreeJS/React Three Fiber, Valtio, Vite, and deployed via Vercel.',
		liveUrl: 'https://moto-configurator-g7jt.vercel.app/',
		githubUrl: 'https://github.com/FrazierMark/Moto_Configurator',
	},
	{
		num: 2,
		id: 'project2',
		title: 'Time Stoppers',
		imageSrc: 'https://i.imgur.com/TcAOeG2.png',
		description:
			"A simple application created through Shopify's Dev Degree program. The goal of this app was to create a stopwatch application in order to practice using the React 'useEffect' hook.",
		liveUrl: 'https://fraziermark.github.io/Time-Stoppers/',
		githubUrl: 'https://github.com/FrazierMark/Time-Stoppers',
	},
	{
		num: 3,
		id: 'project2',
		title: 'Home Of Tomorrow',
		imageSrc: 'https://imgur.com/70sWA3K.png',
		description:
			'This full stack 3D application allows users to view and catalog furniture in 3D. Users can sign-in via Google OAuth 2.0, access fully-textured 3D models from Wayfair’s 3D model API, add specific furniture items to their wishlist, and view, add, edit, or delete private notes. This app utilizes NodeJS, Express, MongoDB / Mongoose, HTML/CSS/JavaScript, ThreeJS, EJS, and is deployed with Heroku.',
		liveUrl: 'https://home-of-tomorrow.herokuapp.com/',
		githubUrl: 'https://github.com/FrazierMark/GA_SEIR_Project2',
	},
	{
		num: 4,
		id: 'project3',
		title: '3D Algorithm Visualizer',
		imageSrc: 'https://imgur.com/pCMZ0pN.png',
		description:
			'This 3D application provides users with a 3D representation of various well-known sorting algorithms including: Merge Sort, Quick Sort, Bubble Sort, Heap Sort, Selection Sort, and Insertion Sort. Users can adjust both the length of the array being sorted as well as the speed of the sorting animation. This app was built with HTML, CSS, Javascript, ThreeJS, Webpack, NodeJS, and is hosted via Google Firebase.',
		liveUrl: 'https://algorithmvisualizer-4346a.web.app/',
		githubUrl: 'https://github.com/FrazierMark/3D_Algorithm_Sorting_Visualizer',
	},
	{
		num: 5,
		id: 'project4',
		title: 'Geo Map',
		imageSrc: 'https://i.imgur.com/NHL3uUm.png',
		description:
			'This full stack 3D application allows users to generate a 3D wireframe-terrain based on real world longitude and latitude. Users can login or signup to create an account, save specific longitude and latitude coordinates, and add or delete private notes based on those locations. This app utilizes NodeJS, Express, MongoDB/Mongoose, ThreeJS, React, Mapbox GL JS, and is deployed with Heroku.',
		liveUrl: 'https://terrainmapp.herokuapp.com/',
		githubUrl: 'https://github.com/FrazierMark/GA_SEIR_Final_Project',
	},
	{
		num: 6,
		id: 'project5',
		title: 'Connect 4 Experience',
		imageSrc: 'https://i.imgur.com/10sBJ4q.png',
		description:
			"An interactive Connect 4 game built using HTML, CSS, JavaScript, and Three.js. This app was created as the first project part of the Adobe Digital Academy and General Assembly's Software Engineering Immersive program. My goal was to redesign this classic game to give the players a unique and new expereince.",
		liveUrl: 'https://fraziermark.github.io/GA_SEIR_Project1/',
		githubUrl: 'https://github.com/FrazierMark/GA_SEIR_Project1',
	},
	{
		num: 7,
		id: 'project6',
		title: 'Tesla Clone',
		imageSrc: 'https://i.imgur.com/nJBR2Iz.png',
		description:
			"A simple application mimicing Tesla's landing page. The goal of this app was to practice using styled components via the material-ui and styled-components modules. The app is hosted via Firebase.",
		liveUrl: 'https://tesla-clone-app-aa2be.web.app/',
		githubUrl:
			'https://github.com/FrazierMark/Learn_React_JS/tree/main/tesla_clone_app',
	},
];

export default projects;
