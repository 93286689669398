
const PlanetRing = ({ position, rotation, innerRadius, outerRadius, thetaSegments, color }) => {

    return (
        <mesh position={position} rotation={rotation} >
            <ringGeometry args={[innerRadius, outerRadius, thetaSegments]} />
            <meshStandardMaterial color={color} />
        </mesh>
    )
}

export default PlanetRing;