import SplitType from "split-type";
import { useRef } from "react";
import gsap from "gsap";
import { useEffect } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function Intro() {
  const sectionRef = useRef();
  const textRef = useRef();
  const imgRef1 = useRef();
  const imgRef2 = useRef();

  useEffect(() => {
    let split = new SplitType(textRef.current);
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: sectionRef.current,
        start: "top 70%",
        end: "bottom 50%",
        toggleActions: "play play none none",
      },
    });
    tl.from(imgRef1.current, {
      opacity: 0.4,
      y: 70,
      duration: 1.5,
      onComplete: scaleDown,
    });

    tl.from(
      imgRef2.current,
      {
        opacity: 0.4,
        y: 70,
        duration: 1.5,
      },
      "<"
    );

    tl.from(
      split.chars,
      {
        duration: 2,
        y: 150,
        x: 30,
        opacity: 0,
        stagger: 0.009,
        ease: "power2.out",
      },
      "+=0.1'"
    );

    function scaleDown() {
      gsap.to(imgRef1.current, {
        scale: 1,
        duration: 2,
      });
      gsap.to(imgRef2.current, {
        scale: 1,
        duration: 2,
      });
    }
  }, []);

  return (
    <section ref={sectionRef} className="about" id="about">
      <h1 id="intro">
        {" "}
        Hi. I'm <span className="name">MARK!</span>{" "}
      </h1>
      <p ref={textRef} className="me_description">
        I'm a creative software developer with a background in film &
        photography production. I'm passionate about the intersection of
        creativity and technology to empower artists and advance storytelling!
        In my spare time I enjoy running, camping, and hanging out with my wife
        and two dogs!
      </p>
      <div className="img-container">
        <div className="photo-border">
          <img
            ref={imgRef1}
            id="photo_me"
            src="https://imgur.com/BmKxXoi.png"
            alt="Mark Frazier"
          />
        </div>
        <div className="photo-border">
          <img
            ref={imgRef2}
            id="photo_me"
            src="https://imgur.com/7lSIoaQ.png"
            alt="Mark Frazier and Dog (Pepper Ann)"
          />
        </div>
      </div>
    </section>
  );
}
