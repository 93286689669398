import { Canvas, useThree, useFrame } from "@react-three/fiber";
import {
  EffectComposer,
  DepthOfField,
  Bloom,
} from "@react-three/postprocessing";
import {
  Environment,
  Loader,
  Scroll,
  ScrollControls,
  Sky,
  useScroll,
} from "@react-three/drei";
import Rocket from "./RocketLoader";
import Planet from "./Planet";
import PlanetRing from "./PlanetRing";
import { Suspense } from "react";
import SuspenseLoader from "./SuspenseLoader";

export default function Scene({ speed = 1, rocketCount = 30, depth = 30 }) {
  return (
    <Canvas
      gl={{ alpha: false, antialias: false }}
      dpr={[1, 1.5]}
      camera={{ near: 0.01, far: 190, fov: 45 }}
    >
      <Suspense fallback={<SuspenseLoader />}>
        {Array.from({ length: rocketCount }, (_, i) => (
          <Rocket key={i} z={-(i / rocketCount) * depth - 20} speed={speed} />
        ))}

        <PlanetRing
          position={[-3.9, 0.2, -70]}
          rotation={[5.2, 1, 0]}
          innerRadius={12}
          outerRadius={15}
          thetaSegments={27}
          color={"#abf7b1"}
        />

        <Planet
          position={[18, 13, -90]}
          size={[10, 20, 20]}
          matCapTexture={
            "https://makio135.com/matcaps/64/A27216_E9D036_D0AB24_DCB927-64px.png"
          }
        />
        <Planet
          position={[-5, 0, -70]}
          size={[7, 20, 20]}
          matCapTexture={
            "https://makio135.com/matcaps/64/7877EE_D87FC5_75D9C7_1C78C0-64px.png"
          }
        />
        <Planet
          position={[-75, 5, 60]}
          size={[5, 20, 20]}
          matCapTexture={
            "https://makio135.com/matcaps/64/F79686_FCCBD4_E76644_E76B56-64px.png"
          }
        />

        <ambientLight intensity={0.7} />

        <EffectComposer multisampling={0}>
          <Bloom luminanceThreshold={1} mipmapBlur />
          <DepthOfField
            target={[0, 0, 10]}
            focalLength={0.3}
            bokehScale={9}
            height={700}
          />
        </EffectComposer>

        <Environment preset={"sunset"} blur={4.8} />

        <Sky
          azimuth={0.01}
          turbidity={10}
          rayleigh={0.5}
          inclination={0.6}
          distance={300}
        />
      </Suspense>
    </Canvas>
  );
}
